'use client';

import { useEffect } from 'react';
import { usePut } from 'bb/api/browser';
import {
    type EmptyResponse,
    type ErrorResponse,
    type Status
} from 'bb/api/browser/types';

type RedeemGiftCardPayload = {
    code: string;
};

export function useRedeemGiftCard(
    code?: string
): [EmptyResponse, ErrorResponse, Status] {
    const { result, error, status, execute } = usePut<
        EmptyResponse,
        RedeemGiftCardPayload
    >('/api/giftcards/redeem');
    useEffect(() => {
        if (code) {
            execute({ code });
        }
    }, [code, execute]);
    return [result, error, status];
}
