'use client';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useIsScreenSize } from 'bb/common/hooks';
import { TOAST_POSITIONS, Toast } from 'bb/ui/Toast';
import { type ToastStoreItem, useToastStore } from '../stores/toastStore';
import css from './toastContainer.module.scss';

/**
 * Keeping this value in a `ref` doesn't work since the component un-mounts
 * and the value is lost. This seems to do the trick fine.
 */
let previousRouterPath = '';

export type ToastPositionProps = {
    toasts: ToastStoreItem[];
    position: NonNullable<ToastStoreItem['position']>;
};

export const ToastPosition = ({ toasts, position }: ToastPositionProps) => {
    const { remove } = useToastStore();

    if (!toasts.length) return null;

    return (
        <div className={classNames(css.root, css[position])}>
            <div className={css.inner}>
                {toasts.map(({ id, onClose: passedOnClose, ...restProps }) => (
                    <Toast
                        key={id}
                        onClose={() => {
                            remove(id);
                            passedOnClose?.();
                        }}
                        {...restProps}
                    />
                ))}
            </div>
        </div>
    );
};

export type ToastContainerProps = {
    /**
     * The current path of the router. This will be `asPath` if
     * using pages router, or `pathname` if using the app router.
     *
     * This is used to determine if there has been a page transition.
     * We need to provide this to not build a dependency on a specfic
     * router.
     *
     * We need this in order to support the `removeOnPageTransition`
     * property in the toast.
     */
    routerPath: string;
};

export const ToastContainer = (props: ToastContainerProps) => {
    const { routerPath } = props;
    const { state, remove } = useToastStore();
    const isMdUp = useIsScreenSize('md');

    useEffect(() => {
        if (previousRouterPath !== routerPath) {
            state.forEach(({ id, onClose, removeOnPageTransition }) => {
                if (removeOnPageTransition) {
                    remove(id);
                    onClose?.();
                }
            });

            previousRouterPath = routerPath;
        }
    }, [routerPath, remove, state]);

    /**
     * On smaller devices we just render the toasts gathered
     * in the center.
     */
    if (!isMdUp) {
        /**
         * Position top-right works fine here since it doesn't
         * really matter in responsive.
         */
        return <ToastPosition {...props} position="top-right" toasts={state} />;
    }

    /**
     * Render by position in desktop.
     */
    return TOAST_POSITIONS.map((position) => (
        <ToastPosition
            {...props}
            key={position}
            position={position}
            toasts={state.filter((item) => item.position === position)}
        />
    ));
};
