import React, { type FunctionComponent } from 'react';
import { MarketPickerButton } from 'bb/app/nav/MarketPicker';
import { isSubMarket } from 'bb/config/marketConfigUtils';
import { type SubMarket } from 'bb/config/types';

export const EuMarketFlag: FunctionComponent<{
    currentMarket: string;
}> = ({ currentMarket }) => {
    if (!isSubMarket(currentMarket as SubMarket)) return null;

    return <MarketPickerButton showCountryName={false} />;
};
