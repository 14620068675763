import {
    createUseApiHook,
    type UseApiPassableOptions
} from 'bb/api/browser/useApi';
import { useTrackingStrategy } from 'bb/tracker/hooks/useTrackingStrategy';
import { useIsLoggedIn } from './useVerifyLoggedIn';

export const useUser = (options?: UseApiPassableOptions<'/api/users'>) => {
    const { track } = useTrackingStrategy();

    const hook = createUseApiHook('/api/users', {
        ...options,
        onData: (result) => {
            track({ event: 'uid_set', uid: result.userid });
            options?.onData?.(result);
        }
    })({ enabled: useIsLoggedIn() });

    return Object.assign(hook, { user: hook.data });
};
