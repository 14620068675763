'use client';

import { useEffect, useRef } from 'react';
import { useStableCallback } from './useStableCallback';

/**
 * Calls `callback` only when value has changed, not on mount
 */
export const useOnChanged = (value: unknown, callback: () => void) => {
    const cb = useStableCallback(callback);
    const val = useRef(value);

    useEffect(() => {
        if (value !== val.current) cb();
        val.current = value;
    }, [value, cb]);
};
