'use client';

import React from 'react';
import { MarketPickerModal } from 'bb/app/nav/MarketPicker';
import { useTranslation } from 'bb/i18n';
import { useModalStore } from 'bb/ui/HeadlessModal/store';

export const GlobalMarketPicker = () => {
    const [[marketPickerOpen, setMarketPickerOpen], getModalProps] =
        useModalStore('marketPicker');
    const { t } = useTranslation(['common']);

    return (
        <MarketPickerModal
            isOpen={marketPickerOpen}
            onAfterClose={() => setMarketPickerOpen(false)}
            buttonProps={{
                'aria-label': t('common:close')
            }}
            {...getModalProps()}
        />
    );
};
