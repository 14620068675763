'use client';

// @ts-strict-ignore
import React, { useContext } from 'react';
import { useLocalObservable } from 'bb/app/stores';
import { type FCChildrenOnly } from 'bb/common/types';
import { accountStore } from '../accountStore';
import { type AccountStore } from '../types';

export const StoreContext = React.createContext<AccountStore | null>(null);

export const AccountStoreProvider: FCChildrenOnly = ({ children }) => {
    const store = useLocalObservable(accountStore);
    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    );
};

export const useAccountStore = (): AccountStore => {
    const store = useContext<AccountStore>(StoreContext);

    if (!store) {
        throw new Error('useAccountStore must be used within StoreProvider');
    }
    return store;
};
