'use client';

import React, {
    createContext,
    type PropsWithChildren,
    useContext
} from 'react';
import { type RouterQuery, type Routes } from 'bb/app/router';
import type { Color } from 'bb/style/types';

export type BreadcrumbsRenderLinkData = {
    key: keyof Routes;
    route: Routes[keyof Routes];
    query: RouterQuery;
    children: React.ReactNode;
    className: string | undefined;
    color?: Color;
};

export type BreadcrumbsRenderLinkContext = {
    render: (data: BreadcrumbsRenderLinkData) => React.ReactNode;
};

export type BreadcrumbsRenderLinkProviderProps = PropsWithChildren &
    BreadcrumbsRenderLinkContext;

export const BreadcrumbsRenderLinkContext =
    createContext<BreadcrumbsRenderLinkContext>({
        render: () => {
            // eslint-disable-next-line no-console
            console.warn(
                `BreadcrumbsRenderLinkContext(): No render function was provided.`
            );
            return null;
        }
    });

export const useBreadcrumbsRenderLink = () =>
    useContext(BreadcrumbsRenderLinkContext);

export const BreadcrumbsRenderLinkProvider = (
    props: BreadcrumbsRenderLinkProviderProps
) => {
    const { children, ...restProps } = props;

    return (
        <BreadcrumbsRenderLinkContext.Provider value={restProps}>
            {children}
        </BreadcrumbsRenderLinkContext.Provider>
    );
};
