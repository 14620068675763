'use client';

import { useEffect, useRef } from 'react';
import useSWR from 'swr';
import { useStableCallback } from 'bb/common/hooks/useStableCallback';
import { useLiveQueries } from '../liveQueries';
import { FIXED_OPTIONS } from '../swr';
import { assignUrl } from './helpers';
import { type UseApiHookArgs, type UseApi } from './useApi.types';

/**
 *
 * @param pathname pathname to the endpoint
 * @param options options passed to useApi
 */
// ...args cannot be inferred properly due to the complex nature
// of the UseApi interface.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useApi: UseApi = (...args) => {
    const [pathname, options] = args as UseApiHookArgs;
    const {
        query,
        onInterpolationError,
        enabled,
        fixed,
        addAsLiveQuery = false,
        /**
         * By SWR defaults this property is set to true. In our case
         * we don't wanna re-run all GET requests when the window is
         * focused because this casues a lot of unnecessary requests.
         */
        revalidateOnFocus = false,
        ...apiOptions
    } = options ?? {};
    const calledOnData = useRef(false);
    const onData = useStableCallback(apiOptions.onData);
    const onFreshData = useStableCallback(apiOptions.onFreshData);

    /**
     * Only try to assign url if enabled is either
     * true or undefined.
     */
    const url = [true, undefined].includes(enabled)
        ? assignUrl(pathname, query, onInterpolationError)
        : null;

    useLiveQueries(addAsLiveQuery ? url : null);

    const swr = useSWR(url, {
        revalidateOnFocus,
        ...apiOptions,
        ...(fixed ? FIXED_OPTIONS : {})
    });

    useEffect(() => {
        if (!calledOnData.current && swr.data) onData(swr.data);
    }, [swr.data, onData]);

    useEffect(() => {
        if (swr.data) onFreshData(swr.data);
    }, [swr.data, onFreshData]);

    return {
        data: swr.data,
        error: swr.error,
        /**
         * We can't spread `swr` here since swr's performance magic will break:
         * https://swr.vercel.app/docs/advanced/performance#dependency-collection
         */
        get isLoading() {
            return swr.isLoading;
        },
        get isValidating() {
            return swr.isValidating;
        },
        mutate: swr.mutate,
        isWaiting: !swr.data && !swr.error,
        meta: {
            pathname,
            url,
            query
        }
    };
};
