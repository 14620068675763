'use client';

import dynamic from 'next/dynamic';

export * from './ToastContainer';

export const LazyToastContainer = dynamic(
    () => import('./ToastContainer').then((mod) => mod.ToastContainer),
    { ssr: false }
);
