'use client';

import { useEffect } from 'react';
import { type AccountAppView } from 'bb/account/types';
import { useHALGet, usePost } from 'bb/api/browser';
import { type ErrorResponse, type Status } from 'bb/api/browser/types';
import { getHalEmbeddedData, getHalLinks } from 'bb/common/hal';
import { type HalLink } from 'bb/common/types';

export function useFetchAppView<Result>(href: string): {
    result: AccountAppView<Result>;
    error: ErrorResponse;
    status: Status;
} {
    const { result, error, status, execute } = usePost<
        AccountAppView<Result>,
        string
    >('/api/follow');
    useEffect(() => {
        if (href) {
            execute(href);
        }
    }, [href, execute]);
    return { result, error, status };
}

export const useAppView = <Result>(link?: HalLink | null) => {
    const { data, error } = useHALGet<AccountAppView<Result>>(
        link ?? undefined,
        { revalidateOnMount: true }
    );

    return { data: getHalEmbeddedData(data), links: getHalLinks(data), error };
};
