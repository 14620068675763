/* eslint-disable react/jsx-props-no-spreading */
import React, { type ComponentType, type ElementType, type JSX } from 'react';
import classnames from 'classnames';
import { type Spacing } from 'bb/style/types';
import { Flex, type FlexProps } from 'bb/ui/Flex/Flex';
import { type Breakpoint, type WithComponentPropsRequired } from 'bb/ui/types';
import {
    type PolymorphicComponent,
    type PropsWithChildrenAndClassName
} from '../types/types';
import css from './gap.module.scss';

export const makeSpacingClassNameKey = (key: Breakpoint, spacing: GapSpacing) =>
    `gap-${key}-${spacing}`;

export const makeSpacingClassNameFromObject = (spacing: GapSpacingSettings) =>
    (Object.entries(spacing) as GapSpacingEntries).reduce(
        (className, [spacingKey, spacingUnit]) =>
            spacingUnit
                ? `${className} ${
                      css[makeSpacingClassNameKey(spacingKey, spacingUnit)]
                  }`
                : className,
        ''
    );

export const makeSpacingClassName = (spacing: GapSpacingProp) =>
    typeof spacing === 'object'
        ? makeSpacingClassNameFromObject(spacing)
        : css[makeSpacingClassNameKey('xxs', spacing)];

/**
 * A spacing unit is equal to 4x ($base-spacing-unit)
 * until > 4. After that breakpoint it equals 8px
 * which is ($base-spacing-unit * 2).
 */
export type GapSpacing = Spacing;

export type GapSpacingProp = GapSpacing | GapSpacingSettings;

export type GapSpacingSettings = Partial<Record<Breakpoint, GapSpacing>>;

export type GapSpacingEntries = [Breakpoint, GapSpacing][];

export type GapProps<
    TComponentType extends ComponentType = typeof Flex,
    TElementType extends ElementType = 'div'
> = PolymorphicComponent<
    TElementType,
    {
        /**
         * Can be either a number or an object if
         * we need different spacings for the
         * given breakpoints.
         */
        spacing?: GapSpacingProp;
        /**
         * The component composes <Flex> by default
         * but we can compose any component.
         */
        composes?: TComponentType;
    } & WithComponentPropsRequired<
        TComponentType,
        PropsWithChildrenAndClassName
    >
>;

/**
 * Util-component for handling spacings using CSS Gap. Useful
 * for defining gaps between elements through different
 * breakpoints. It composes the <Flex> component by default so
 * we don't have to write CSS.
 *
 * A normal usage would look something like this:
 *
 * <Gap spacing={{ xxs: 3, md: 6 }}>
 *      <Text>Foo</Text>
 *      -- 3 units of space (12 px) up to md --
 *      -- 6 units of space (32 px) from md  --
 *      <Text>Foo</Text>
 *      -- 3 units of space (12 px) up to md --
 *      -- 6 units of space (32 px) from md  --
 *      <Text>Foo</Text>
 * </Gap>
 *
 * If we need to compose a different element than
 * <Flex> we specify it through the composes prop.
 *
 * <Gap composes={Stack} spacing={{ xxs: 5, md: 10 }}>
 *      <Image src="/cool.png" />
 *      -- 5 units of space (24 px) up to md --
 *      -- 10 units of space (64 px) from md --
 *      <Image src="/cool.png" />
 *      -- 5 units of space (24 px) up to md --
 *      -- 10 units of space (64 px) from md --
 *      <Image src="/cool.png" />
 * </Gap>
 */
export const Gap = ((props: GapProps<typeof Flex, 'div'>) => {
    const {
        className,
        children,
        spacing,
        composes: Component = Flex,
        as,
        ref,
        /**
         * Any component that we choose to compose
         * will receive the props it accepts if we
         * pass it to <Gap>. Keep in mind it does
         * need to accept a className prop for the
         * gap classNames to be applied.
         */
        ...restProps
    } = props;

    const defaultProps: Omit<FlexProps<'div'>, 'gap'> = Component === Flex
        ? {
              /**
               * Default to 'column' since it is
               * the most common flow.
               */
              direction: (props as FlexProps).direction ?? 'column',
              wrap: (props as FlexProps).wrap ?? 'noWrap'
          }
        : {};

    return (
        <Component
            className={classnames(
                css.root,
                spacing && makeSpacingClassName(spacing),
                className
            )}
            ref={ref}
            as={as}
            {...defaultProps}
            {...restProps}
        >
            {children}
        </Component>
    );
}) as <
    TComponentType extends ComponentType = typeof Flex,
    TElementType extends ElementType = 'div'
>(
    props: GapProps<TComponentType, TElementType>
) => JSX.Element;
