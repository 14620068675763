import { mutate } from 'swr';
import { type ApiPaths } from '../types';

// add commonly revalidated endpoints to this list
const REFRESHABLE_PATHS = {
    account: '/api/my/account',
    accountViews: '/api/views/web/account',
    loggedIn: '/api/login/verify',
    ownedEditions: '/api/my/editions/owned/all',
    user: '/api/users',
    cancellation: '/api/views/web/account/cancelsubscription',
    notifications: '/api/notifications',
    nextpayment: '/api/my/products/nextpayment'
} satisfies {
    [s: string]: keyof ApiPaths;
};

export const refresh = <Path extends keyof typeof REFRESHABLE_PATHS>(
    path: Path,
    isLoggedIn?: Path extends 'loggedIn' ? boolean : never
) =>
    mutate(
        REFRESHABLE_PATHS[path],
        typeof isLoggedIn === 'boolean' ? { isLoggedIn } : undefined,
        { revalidate: true }
    );
