'use client';

import { type UseApiOptions, useApi } from 'bb/api/browser/useApi';
import { type NextApi } from 'bb/api/types';

export const useVerifyLoggedIn = (
    config?: UseApiOptions<NextApi<'/api/login/verify', 'get'>['response']>
) => {
    return useApi('/api/login/verify', {
        ...config,
        revalidateOnFocus: true
    });
};

export const useIsLoggedIn = () => {
    const { data: { isLoggedIn } = { isLoggedIn: false } } =
        useVerifyLoggedIn();

    return isLoggedIn;
};
