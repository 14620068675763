import { useIsLoggedIn } from 'bb/account/hooks/useVerifyLoggedIn';
import { useApi, type UseApiPassableOptions } from 'bb/api/browser/useApi';

export const useAccount = (
    config?: UseApiPassableOptions<'/api/my/account'>
) => {
    const res = useApi('/api/my/account', {
        ...config,
        enabled: useIsLoggedIn() && (config?.enabled ?? true)
    });

    return Object.assign(res, { account: res.data });
};
