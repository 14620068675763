import React, { type JSX } from 'react';
import classnames from 'classnames';
import { makeCSSVariableFromColor } from 'bb/style/utils';
import { FontStyle } from 'bb/ui/Styles';
import css from './typography.module.scss';
import {
    type TypographyProps,
    type TypographyVariant
} from './Typography.types';

export const DEFAULT_TYPOGRAPHY_ELEMENT = 'span';

const headingPrimary = classnames(css.lineHeightSlim, FontStyle.primaryBold);

const headingSecondary = classnames(
    css.lineHeightSlim,
    FontStyle.secondaryRegular
);

const { body1 = '', body2 = '', body3 = '' } = css;

export const typographyStyles: Record<TypographyVariant, string> = {
    body1,
    body2,
    body3,
    megaPrimary: classnames(css.mega, headingPrimary),
    megaSecondary: classnames(css.mega, headingSecondary),
    hugePrimary: classnames(css.huge, headingPrimary),
    hugeSecondary: classnames(css.huge, headingSecondary),
    h1Primary: classnames(css.h1, headingPrimary),
    h1Secondary: classnames(css.h1, headingSecondary),
    h2Primary: classnames(css.h2, headingPrimary),
    h2Secondary: classnames(css.h2, headingSecondary),
    h3Primary: classnames(css.h3, headingPrimary, css.lineHeightAverage),
    h3Secondary: classnames(css.h3, headingSecondary),
    h4Primary: classnames(css.h4, headingPrimary, css.lineHeightBig),
    h4Secondary: classnames(css.h4, headingSecondary, css.lineHeightBig),
    h5Primary: classnames(css.h5, headingPrimary, css.lineHeightBig),
    h5Secondary: classnames(css.h5, headingSecondary, css.lineHeightBig),
    h6Primary: classnames(css.h6, headingPrimary, css.lineHeightBig),
    h6Secondary: classnames(css.h6, headingSecondary, css.lineHeightBig),
    button: classnames(css.lineHeightBig, css.bold, css.body1),
    link1: classnames(body1, css.underline),
    link2: classnames(body2, css.underline),
    link3: classnames(body3, css.underline),
    label: classnames(body2, css.bold),
    input: body1,
    badge: classnames(css.badge, headingPrimary)
};

/**
 * This component contains all the variants of the typography currently
 * in use. It is encouraged to use this component directly if we just
 * want to render a text that doesn't need a translation. If we are
 * using a translation, it is encouraged to use the Text component which
 * in turn uses this component.
 */
export const Typography = ((props: TypographyProps<'span'>) => {
    const {
        as = 'span',
        className,
        bold,
        upperCase,
        color,
        center,
        children,
        block,
        variant,
        responsive = true,
        underline = false,
        breakWord = false,
        style: passedStyle = {},
        ref,
        ...restProps
    } = props;

    /**
     * This component does not assume any semantics
     * in relation to the variants. The default is
     * always a span, but can be overwritten with
     * the as property.
     */
    const Component = as;

    return (
        <Component
            ref={ref}
            style={{
                ...passedStyle,
                ...makeCSSVariableFromColor('--typography-color', color)
            }}
            className={classnames(
                css.root,
                color && css.color,
                variant && typographyStyles[variant],
                bold && css.bold,
                upperCase && css.upperCase,
                center && css.center,
                block && css.block,
                responsive && css.responsive,
                underline && css.underline,
                breakWord && css.breakWord,
                className
            )}
            // Any property that is available to pass to the element
            // specified will be also be available to this component
            // and will be spread.
            {...restProps}
        >
            {children}
        </Component>
    );
}) as <T extends React.ElementType = 'span'>(
    props: TypographyProps<T>
) => JSX.Element;
