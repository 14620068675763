/* eslint-disable @next/next/no-img-element */
import React, { type FC } from 'react';
import { ExternalLink } from 'bb/common/ExternalLink';
import { getAllMarketConfigsAsArray } from 'bb/config/marketConfig';
import { Text } from 'bb/i18n/Text';
import { Gap } from 'bb/ui/Gap';
import { List, ListItem } from 'bb/ui/List';
import css from './marketPicker.module.scss';

const MARKET_CONFIGS = getAllMarketConfigsAsArray();

export const MarketPicker: FC<{ isOpen?: boolean }> = ({ isOpen = true }) => (
    <List className={css.root}>
        {MARKET_CONFIGS.map((market) => (
            <ListItem key={market.marketCode}>
                <ExternalLink
                    href={`/${market.marketCode}`}
                    className={css.link}
                    aria-label={market.name}
                    target="_self"
                >
                    <Gap spacing={4} direction="row" alignItems="center">
                        {isOpen && (
                            <img
                                alt={market.name}
                                className={css.flag}
                                src={`/images/flags/${market.marketCode}.png`}
                                loading="lazy"
                            />
                        )}
                        <Text>{market.name}</Text>
                    </Gap>
                </ExternalLink>
            </ListItem>
        ))}
    </List>
);
