import React, { type ReactElement, type JSX } from 'react';
import classnames from 'classnames';
import { type BaseIconProps } from 'bb/ui/Icons/Icon';
import {
    Button,
    TextButton,
    type ButtonProps,
    type ButtonVariant,
    type TextButtonProps
} from '../Button';
import { getMargins } from '../Margin';
import {
    type ButtonProps as DeprecatedButtonProps,
    type ButtonVariant as DeprecatedButtonVariant
} from './Button.types';
import css from './deprecatedButton.module.scss';

export type DeprecatedButtonTextVariant = 'underline' | 'text';

const getRefactoredButtonVariantProps = (
    props: DeprecatedButtonProps<DeprecatedButtonVariant>
) => {
    const { variant = 'primary' } = props;

    const refactoredButtonProps: Partial<ButtonProps<ButtonVariant>> = {};

    switch (variant) {
        case 'primary':
            refactoredButtonProps.variant = 'contained';
            refactoredButtonProps.color = 'primary-purple';
            break;
        case 'secondary':
            refactoredButtonProps.variant = 'outlined';
            refactoredButtonProps.color = 'primary-black';
            break;
        case 'tertiary':
            refactoredButtonProps.variant = 'contained';
            refactoredButtonProps.color = 'primary-black';
            break;
        case 'quaternary':
            refactoredButtonProps.variant = 'contained';
            refactoredButtonProps.color = 'secondary-yellow';
            break;

        default:
            break;
    }

    return refactoredButtonProps;
};

const getRefactoredTextButtonProps = (
    props: DeprecatedButtonProps<DeprecatedButtonTextVariant>
) => {
    const { variant, inverted, selected } = props;

    const refactoredButtonProps: Partial<TextButtonProps> = {};

    if (variant === 'underline' || selected) {
        refactoredButtonProps.underline = true;
    }

    if (inverted) {
        refactoredButtonProps.color = 'primary-white';
    }

    return refactoredButtonProps;
};

const getRefactoredButtonCommonProps = (
    props: DeprecatedButtonProps<DeprecatedButtonVariant>
) => {
    const {
        icon,
        iconSize = 'medium',
        iconPosition = 'before',
        disableStates
    } = props;

    const refactoredButtonProps: Partial<
        ButtonProps<ButtonVariant> | TextButtonProps
    > = {};

    const augmentedIcon =
        icon &&
        React.cloneElement(icon as ReactElement<BaseIconProps>, {
            size: iconSize,
            inline: true
        });

    if (icon) {
        refactoredButtonProps[
            iconPosition === 'before' ? 'startAdornment' : 'endAdornment'
        ] = augmentedIcon;
    }

    if (disableStates) {
        refactoredButtonProps.disableAnimation = true;
    }

    return refactoredButtonProps;
};

/**
 * @deprecated Use `Button` instead
 */
export const DeprecatedButton = ((
    props: DeprecatedButtonProps<DeprecatedButtonVariant>
) => {
    const {
        as = 'button',
        className: passedClassName,
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        /**
         * Properties that aren't to be passed to any of the
         * new button components.
         */
        size: _size,
        color: _color,
        inverted = false,
        inline: _inline,
        icon: _icon,
        iconPosition: _iconPosition,
        iconSize: _iconSize,
        disableStates: _disableStates,
        selected: _selected,
        debounceClick: _debounceClick,
        variant = 'primary',
        ref,
        ...restProps
    } = props;

    const className = classnames(
        getMargins({
            margin,
            marginLeft,
            marginRight,
            marginBottom,
            marginTop
        }),
        inverted && css.inverted,
        passedClassName
    );

    const refactoredCommonButtonProps = getRefactoredButtonCommonProps(props);

    const commonProps = {
        ...refactoredCommonButtonProps,
        as,
        className
    };

    if (['text', 'underline'].includes(variant)) {
        const refactoredTextButtonProps = getRefactoredTextButtonProps({
            ...props,
            variant: variant as DeprecatedButtonTextVariant
        });

        return (
            <TextButton
                ref={ref}
                {...restProps}
                {...commonProps}
                {...refactoredTextButtonProps}
            />
        );
    }

    const refactoredButtonVariantProps = getRefactoredButtonVariantProps(props);

    return (
        <Button
            ref={ref}
            {...restProps}
            {...commonProps}
            {...refactoredButtonVariantProps}
        />
    );
}) as <
    TButtonVariant extends DeprecatedButtonVariant = 'primary',
    TElementType extends 'button' | 'a' = 'button'
>(
    props: DeprecatedButtonProps<TButtonVariant, TElementType>
) => JSX.Element;
