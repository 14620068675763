import React, { type JSX } from 'react';
import classNames from 'classnames';
import { type PolymorphicComponent } from '../types';
import { Card, type CardProps } from './Card';
import css from './pageContentCard.module.scss';

export const PageContentCard = ((props: CardProps<'div'>) => {
    const { children, className, ref, ...rest } = props;
    return (
        <Card
            {...rest}
            className={classNames(css.padding, className)}
            ref={ref}
        >
            {children}
        </Card>
    );
}) as <T extends React.ElementType>(
    props: PolymorphicComponent<T, CardProps<T>>
) => JSX.Element;
