import React, { type FunctionComponent } from 'react';
import { useFormatAmount } from './useFormatAmount';

export const Amount: FunctionComponent<{
    amount?: number;
}> = ({ amount }) => {
    const formattedAmount = useFormatAmount(amount);

    return <>{formattedAmount}</>;
};
