'use client';

import { type ParsedUrlQuery } from 'querystring';
import { useCallback, useRef } from 'react';
import { useRouter as useNextRouter } from 'next/router';
import { useGetRoutesFromRemappedRoutes } from 'bb/app/router/useGetRoutesFromRemappedRoutes';
import { useApp } from '../AppContext';
import { type Route, type Routes, routes } from './config';
import { type Router, type TransitionOptions } from './types';
import { usePersistQueryParams } from './usePersistQueryParams';

export type RerouteOptions = TransitionOptions & {
    /**
     * The method to use for the reroute.
     *
     * @defaultValue `'replace'`
     */
    method?: 'replace' | 'push';
    /**
     * Array of strings to omit from the router query.
     */
    omitKeys?: string[];
};

export function useRouter(): Router {
    const router = useNextRouter();
    const persistQueryParams = usePersistQueryParams();
    const { make } = persistQueryParams;
    const routerRef = useRef(router);
    const { locale, market } = useApp();
    const getRouteFromRemappedRoutes = useGetRoutesFromRemappedRoutes();

    const navigate: Router['navigate'] = useCallback(
        async (originalRoute, query?, options?, as?) => {
            if (!originalRoute?.href) {
                return;
            }

            const route =
                routes[
                    getRouteFromRemappedRoutes(originalRoute as Routes[Route])
                ] ?? originalRoute;

            await routerRef.current.push(
                {
                    pathname: route.href,
                    query: { ...query, ...make(), market }
                },
                as || undefined,
                options
            );
        },
        [make, market, getRouteFromRemappedRoutes]
    );

    const reroute = useCallback(
        (query: ParsedUrlQuery = {}, options: RerouteOptions = {}) => {
            const {
                shallow = true,
                method = 'replace',
                omitKeys = [],
                ...restOptions
            } = options;
            const _router = routerRef.current;

            const routerQueryCopy = { ..._router.query };
            omitKeys.forEach((key) => {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete routerQueryCopy[key];
            });

            return _router[method](
                {
                    pathname: _router.pathname,
                    query: { ...routerQueryCopy, ...query, market }
                },
                undefined,
                {
                    shallow,
                    ...restOptions
                }
            );
        },
        [market]
    );

    return {
        navigate,
        router: Object.assign(routerRef.current, router, { locale }),
        routes,
        persistQueryParams,
        reroute
    };
}
