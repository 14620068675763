'use client';

import React, {
    createContext,
    useContext,
    type PropsWithChildren
} from 'react';
import { type Locale } from 'bb/config/marketConfigUtils';
import { type Market } from 'bb/config/types';
import {
    type TrackingStrategy,
    type AnalyticsEventsStrategy
} from 'bb/tracker/types';

export type AppContextType = {
    trackingStrategy: TrackingStrategy;
    analyticsEventTrackingStrategy: AnalyticsEventsStrategy;
    market: Market;
    locale: Locale;
};

export const appContextDefaultValues: AppContextType = {
    trackingStrategy: {
        track: () => null
    },
    analyticsEventTrackingStrategy: {
        track: () => null
    },
    market: '' as Market,
    locale: 'en' as Locale
};

export const AppContext = createContext<AppContextType>(
    appContextDefaultValues
);

export const useApp = () => useContext(AppContext);

export type AppProviderProps = PropsWithChildren &
    Pick<AppContextType, 'locale' | 'market'>;

export const AppProvider = (props: AppProviderProps) => {
    const { children, ...passedValues } = props;

    const ctx: AppContextType = {
        ...appContextDefaultValues,
        ...passedValues
    };

    return <AppContext.Provider value={ctx}>{children}</AppContext.Provider>;
};
