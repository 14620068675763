import React, { type JSX } from 'react';
import classnames from 'classnames';
import { Box, type BoxProps } from '../Box';
import { type AlignItems, type DataKey } from '../types';
import css from './card.module.scss';

export type CardProps<TElementType extends React.ElementType = 'div'> = {
    withBorder?: boolean;
    alignItems?: AlignItems;
    dataKey?: DataKey;
    noSmPadding?: boolean;
    grow?: boolean;
} & Omit<BoxProps<TElementType>, 'border'>;

/**
 * @deprecated Use Box instead.
 */
export const Card = (({
    withBorder,
    alignItems,
    dataKey,
    noSmPadding,
    className,
    children,
    grow = false,
    bgColor = 'primary-white',
    ref,
    ...rest
}: CardProps<'div'>) => (
    <Box
        ref={ref}
        className={classnames(className, {
            [css[alignItems as string] as string]: Boolean(alignItems),
            [css.zeroPadding as string]: Boolean(noSmPadding),
            [css.grow as string]: grow
        })}
        border={withBorder ? '1px solid gray-02' : undefined}
        data-key={dataKey}
        bgColor={bgColor}
        {...rest}
    >
        {children}
    </Box>
)) as <TElementType extends React.ElementType = 'div'>(
    props: CardProps<TElementType>
) => JSX.Element;
