'use client';

import React from 'react';
import classnames from 'classnames';
import {
    CloseButton,
    type CloseButtonProps
} from 'bb/common/components/buttons/CloseButton';
import { Text } from 'bb/i18n/Text';
import { Gap } from 'bb/ui/Gap';
import { BaseModal, type BaseModalProps } from 'bb/ui/Modal';
import { createModalPortal } from 'bb/ui/Modal/utils';
import { MarketPicker } from './MarketPicker';
import css from './marketPicker.module.scss';

export type MarketPickerModalProps = {
    /**
     * Limits the max-height of the content
     * to 100% - var(--navbar-height)-slimso we always
     * can see the nav.
     */
    limitHeight?: boolean;
    buttonProps?: CloseButtonProps;
} & BaseModalProps;

/**
 * Unfortunately this component cannot use the Modal
 * component that we already have since their behaviours
 * diverge too much.
 */
export const MarketPickerModal = ({
    onRequestClose,
    onAfterClose,
    limitHeight = false,
    disableOverlay = true,
    buttonProps,
    persistChildrenWhenClosed = true,
    entityName,
    isOpen,
    ...restProps
}: MarketPickerModalProps) =>
    createModalPortal(
        <BaseModal
            // Corresponds with transition in CSS-file
            onRequestClose={onRequestClose}
            onAfterClose={onAfterClose}
            disableOverlay={disableOverlay}
            closeTimeoutMS={400}
            boxProps={{
                className: classnames(
                    css.modalContent,
                    limitHeight && css.limitHeight
                ),
                bgColor: 'primary-black'
            }}
            persistChildrenWhenClosed={persistChildrenWhenClosed}
            isOpen={isOpen}
            {...restProps}
        >
            {({ handleClose }) => (
                <Gap className={css.modalInner} spacing={6}>
                    <Text
                        as="h3"
                        variant="h2Secondary"
                        center
                        color="primary-white"
                        t="countries:selectCountry"
                        id={entityName}
                    />
                    <MarketPicker isOpen={isOpen} />

                    {(onRequestClose || onAfterClose) && (
                        <CloseButton
                            className={css.close}
                            color="primary-white"
                            onClick={handleClose}
                            {...buttonProps}
                        />
                    )}
                </Gap>
            )}
        </BaseModal>
    );
