'use client';

import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';
import { shortSwitch } from 'bb/utils';
import { api } from '../api';
import { handleError } from '../handleError';
import { interpolateDynamicSegment } from '../useApi';
import {
    type UseMutation,
    type UseMutationHookArgs
} from './useMutation.types';

const MISSING_KEY = 'MISSING_KEY';

/**
 * @param method 'post' | 'put' | 'delete'
 * @param pathname pathname to the endpoint
 * @param options options passed to useApi
 */
// ...args cannot be inferred properly due to the complex nature
// of the UseApi interface.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useMutation: UseMutation = (...args) => {
    const [method, pathname, options] = args as UseMutationHookArgs;
    const { query, ...mutationOptions } = options ?? {};
    const interPolatedUrlRef = useRef('');

    const swr = useSWRMutation(
        // passing `null` here makes trigger throw an un-catchable error
        pathname ?? MISSING_KEY,
        async (k, { arg }) => {
            const _url = k === MISSING_KEY ? '' : k;
            if (!_url) throw Error('No url provided to mutation hook');

            const { result: url, rest: body } = interpolateDynamicSegment(
                _url,
                arg
            );

            if (!url) throw Error('Failed to interpolate url');
            interPolatedUrlRef.current = url;

            const data = mutationOptions.beforeSubmit?.(body) ?? body;

            const request = shortSwitch(
                method,
                ['post', () => api.post(url, data)],
                ['put', () => api.put(url, data)],
                ['delete', () => api.delete(url, { data })]
            );

            if (!request)
                throw Error(
                    `HTTP method must be either 'post', 'put' or 'delete`
                );

            return request()
                .then((res) => res.data)
                .catch((err) => {
                    throw handleError(err);
                });
        },
        {
            throwOnError: false,
            ...mutationOptions
        }
    );

    return {
        data: swr.data,
        error: swr.error,
        /**
         * We can't spread `swr` here since swr's performance magic will break:
         * https://swr.vercel.app/docs/advanced/performance#dependency-collection
         */
        isMutating: swr.isMutating,
        trigger: swr.trigger,
        isWaiting: !swr.data && !swr.error,
        meta: {
            pathname,
            url: interPolatedUrlRef.current,
            query
        },
        reset: swr.reset
    };
};
