import React, { type PropsWithChildren } from 'react';
import { BreadcrumbsRenderLinkProvider } from 'bb/app/nav/Breadcrumbs/BreadcrumbsRenderLinkContext';
import { Link } from 'bb/app/nav/Link';

export type BreadcrumbsRenderLinkPagesProviderProps = PropsWithChildren;

export const BreadcrumbsRenderLinkPagesProvider = (
    props: BreadcrumbsRenderLinkPagesProviderProps
) => {
    const { children: passedChildren } = props;

    return (
        <BreadcrumbsRenderLinkProvider
            render={(ctx) => {
                const { children, key, ...restProps } = ctx;

                return (
                    <Link key={key} {...restProps}>
                        {children}
                    </Link>
                );
            }}
        >
            {passedChildren}
        </BreadcrumbsRenderLinkProvider>
    );
};
