/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { useApp } from 'bb/app/AppContext';
import { getMarketConfig } from 'bb/config/marketConfig';
import { Text } from 'bb/i18n/Text';
import { useTranslation } from 'bb/i18n/translationUtils';
import { Gap } from 'bb/ui/Gap';
import { useModalStore } from 'bb/ui/HeadlessModal/store';
import css from './marketPicker.module.scss';

export const MarketPickerButton = ({ showCountryName = true }) => {
    const { market } = useApp();
    const { t } = useTranslation(['footer', 'common']);

    const [, getButtonProps] = useModalStore('marketPicker');

    const { key: marketKey, marketCode, name } = getMarketConfig(market) || {};

    return (
        <button
            {...getButtonProps()}
            className={css.marketPickerButton}
            aria-label={t('footer:marketSelectorButton') ?? ''}
            type="button"
        >
            <Gap aria-hidden spacing={4} direction="row" alignItems="center">
                <img
                    alt={name}
                    className={css.flag}
                    src={`/images/flags/${marketCode}.png`}
                    loading="lazy"
                />
                {showCountryName && (
                    <Text
                        className={css.marketPickerText}
                        color="primary-white"
                        t={`countries:${marketKey}`}
                    />
                )}
            </Gap>
        </button>
    );
};
