import { type LinkProps } from 'next/link';
import { isObject } from 'bb/utils';

export function transformHref(href: LinkProps['href']): string {
    if (typeof href === 'string') {
        return href;
    }

    // eslint-disable-next-line prefer-const
    let { pathname, query } = href as Exclude<LinkProps['href'], string>;
    const usedKeys = new Set<string>();

    if (!pathname) return '';

    if (!isObject(query)) return pathname;

    // Replace dynamic segments
    for (const key in query) {
        const value = query[key];

        if (!value) continue;

        const dynamicSegment = `[${key}]`;
        const catchAllSegment = `[...${key}]`;

        if (pathname.includes(dynamicSegment)) {
            pathname = pathname.replace(
                dynamicSegment,
                Array.isArray(value) ? value[0] : value
            );
            usedKeys.add(key);
        } else if (pathname.includes(catchAllSegment)) {
            pathname = pathname.replace(
                catchAllSegment,
                (Array.isArray(value) ? value.join('/') : value).toString()
            );
            usedKeys.add(key);
        }
    }

    const remainingQuery = new URLSearchParams(
        Object.entries(query)
            .filter(([key]) => !usedKeys.has(key))
            .reduce(
                (acc, [key, value]) => {
                    const res = Array.isArray(value) ? value.join(',') : value;

                    if (typeof res === 'string') {
                        acc[key] = res;
                    }

                    return acc;
                },
                {} as Record<string, string>
            )
    ).toString();

    return remainingQuery ? `${pathname}?${remainingQuery}` : pathname;
}
